import React from 'react';
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';
import { Button, Headline } from '@sumup/circuit-ui';
import isEmpty from 'lodash/fp/isEmpty';

import { Props } from './interfaces';
import EmbeddedCTAImage from './components/EmbeddedCTAImage';
import { IMAGE_BACKGROUND, IMAGE_DISPLAY } from './constants';

import Link from '~/shared/components/Link';
import dataSelector from '~/shared/util/data-selector';
import * as inline from '~/shared/components/RichText/configs/inline';
import RichText from '~/shared/components/RichText';

const wrapperStyles = ({
  theme,
  hasImage,
}: {
  theme?: Theme;
  hasImage: boolean;
}) => css`
  text-align: center;
  border: ${theme.borderWidth.mega} solid var(--cui-border-subtle);
  border-radius: ${theme.borderRadius.mega};
  padding: ${theme.spacings.tera};
  margin: ${theme.spacings.tera} 0;
  margin-right: ${hasImage ? '-25%' : 0};
  display: flex;
  flex-direction: row;
  align-items: center;

  ${theme.mq.untilMega} {
    padding: ${theme.spacings.tera} ${theme.spacings.giga};
    margin: ${theme.spacings.tera} ${hasImage ? '-12%' : 0};
  }

  ${theme.mq.untilKilo} {
    padding: calc(${theme.spacings.giga} + ${theme.spacings.bit})
      calc(${theme.spacings.mega} + ${theme.spacings.bit});
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
`;
const Wrapper = styled.div(wrapperStyles);

const contentWrapperStyles = ({ hasImage }: { hasImage: boolean }) => css`
  flex: 1;
  text-align: ${hasImage ? 'left' : 'center'};
`;

const ContentWrapper = styled.div(contentWrapperStyles);

const buttonStyles = ({ theme }: { theme: Theme }) => css`
  margin-top: ${theme.spacings.giga};
  ${theme.mq.untilKilo} {
    width: 100%;
  }
`;
const StyledButton = styled(Button)(buttonStyles);
const StyledHeadline = styled(Headline)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.giga};
  `,
);

function EmbeddedCtaBanner({
  headline,
  description,
  buttonLabel,
  buttonUrl,
  buttonTrackingId,
  buttonOptimizelyFullStackClickEvents,
  primary = true,

  image,
  imageBackground = IMAGE_BACKGROUND.WHITE,
  imageDisplay = IMAGE_DISPLAY.FIT,
  imageCaption,

  contentType,
  name,
  id,
}: Props) {
  if (!headline && isEmpty(description) && !buttonLabel) {
    return null;
  }
  const dataSelectorValue = primary ? 'button_primary' : 'button_secondary';
  const hasImage = !isEmpty(image);

  return (
    <Wrapper hasImage={hasImage}>
      {hasImage && (
        <EmbeddedCTAImage
          image={image}
          imageBackground={imageBackground}
          imageDisplay={imageDisplay}
          imageCaption={imageCaption}
        />
      )}
      <ContentWrapper hasImage={hasImage}>
        {headline && (
          <StyledHeadline size="two" as="h2">
            {headline}
          </StyledHeadline>
        )}
        {!isEmpty(description) && (
          <RichText
            richText={description}
            renderNode={inline.createRenderNode()}
            renderMark={inline.createRenderMark()}
          />
        )}
        {buttonLabel && (
          <Link
            trackingId={buttonTrackingId}
            href={buttonUrl}
            optimizelyFullStackClickEvents={
              buttonOptimizelyFullStackClickEvents
            }
            trackingContentEntry={{
              contentType,
              id,
              name,
            }}
          >
            <StyledButton
              data-selector={dataSelector(dataSelectorValue, 'richText')}
              variant={primary ? 'primary' : 'secondary'}
            >
              {buttonLabel}
            </StyledButton>
          </Link>
        )}
      </ContentWrapper>
    </Wrapper>
  );
}

EmbeddedCtaBanner.displayName = 'EmbeddedCtaBanner';

export default EmbeddedCtaBanner;
