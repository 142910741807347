import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Table = styled('table')(css`
  margin-bottom: 1.5em;
  border-collapse: collapse;
  border-radius: 5px;
  border-style: hidden;
  box-shadow: rgb(174 193 204) 0px 0px 0px 1px;
  width: 100%;
  table-layout: fixed;
  overflow: hidden;
`);

export const TableRow = styled('tr')(css`
  border: 1px solid rgb(174, 193, 204);
  border-collapse: collapse;
`);

export const TableCell = styled('td')(css`
  border: 1px solid rgb(174, 193, 204);
  border-collapse: collapse;
  padding: 10px 12px;
  min-width: 48px;
  position: relative;
  vertical-align: top;
  word-break: break-word;

  p:last-of-type {
    margin-bottom: 0px;
  }
`);

export const TableHeaderCell = styled('th')(css`
  background-clip: padding-box;
  background-color: rgb(231, 235, 238);
  border: 1px solid rgb(174, 193, 204);
  border-collapse: collapse;
  padding: 10px 12px;
  font-weight: 400;
  text-align: left;
  min-width: 48px;
  position: relative;
  word-break: break-word;
  hyphens: manual;

  p:last-of-type {
    margin-bottom: 0px;
  }
`);
