import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline, Body, List, Hr } from '@sumup/circuit-ui';
import { spacing } from '@sumup/circuit-ui/legacy';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { MergeTag } from '@ninetailed/experience.js-next';

import EmbeddedImage from '../components/EmbeddedImage';
import EmbeddedVideo from '../components/EmbeddedVideo';
import EmbeddedFrame from '../components/EmbeddedFrame';
import EmbeddedHighlight from '../components/EmbeddedHighlight';
import EmbeddedButton from '../components/EmbeddedButton';
import EmbeddedVariable from '../components/EmbeddedVariable';
import EmbeddedLink from '../components/EmbeddedLink';
import { getFields, getContentType } from '../util';
import slugify from '../../../util/slugify';
import Align from '../components/Align';
import EmbeddedRichtext from '../components/EmbeddedRichtext';
import {
  Table,
  TableRow,
  TableCell,
  TableHeaderCell,
} from '../components/Table';

import Anchor from '~/shared/components/Anchor';
import EmbeddedCtaBanner from '~/shared/components/RichText/components/EmbeddedCtaBanner';
import { NINETAILED_IS_ENABLED } from '~/shared/services/ninetailed/constants';

const headlineStyles = ({ theme }) => css`
  margin-top: ${theme.spacings.giga};
  margin-bottom: ${theme.spacings.mega};
  position: relative;
`;

const StyledHeadline = styled(Headline)(headlineStyles);

const anchorStyles = ({ theme }) => css`
  position: absolute;
  opacity: 0;
  top: calc(-${theme.spacings.nav.desktop} - 20px);
`;
const StyledAnchor = styled('a')(anchorStyles);

const StyledBodyBold = styled(Body)(css`
  font-size: inherit !important;
`);

const StyledBodyItalic = styled(Body)(css`
  font-size: inherit !important;
  font-weight: inherit !important;
  font-style: italic;
`);

const StyledList = styled(List)(({ theme, size }) => {
  const sizeMap = {
    one: theme.spacings.byte,
    two: theme.spacings.kilo,
  };

  return css`
    margin-bottom: ${theme.spacings.mega};

    li:last-child,
    ul:last-child,
    ol:last-child {
      margin-bottom: ${sizeMap[size]};
    }
  `;
});

export function createRenderNode(trackingContentEntry) {
  return {
    [BLOCKS.HEADING_1]: (node, children) => (
      <StyledHeadline as="h1" size="one">
        <StyledAnchor id={slugify(children)}> </StyledAnchor>
        {children}
      </StyledHeadline>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <StyledHeadline as="h2" size="one">
        <StyledAnchor id={slugify(children)}> </StyledAnchor>
        {children}
      </StyledHeadline>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <StyledHeadline as="h3" size="three">
        <StyledAnchor id={slugify(children)}> </StyledAnchor>
        {children}
      </StyledHeadline>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <StyledHeadline as="h4" size="four">
        <StyledAnchor id={slugify(children)}> </StyledAnchor>
        {children}
      </StyledHeadline>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <StyledHeadline as="h5" size="four">
        <StyledAnchor id={slugify(children)}> </StyledAnchor>
        {children}
      </StyledHeadline>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <StyledHeadline as="h6" size="four">
        <StyledAnchor id={slugify(children)}> </StyledAnchor>
        {children}
      </StyledHeadline>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Body size="one" css={spacing({ bottom: 'mega' })}>
        {children}
      </Body>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <StyledList size="one">{children}</StyledList>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <StyledList size="one" variant="ordered">
        {children}
      </StyledList>
    ),
    [BLOCKS.QUOTE]: (node, children) => (
      <Body variant="quote" size="one" css={spacing({ bottom: 'mega' })}>
        {children}
      </Body>
    ),
    [BLOCKS.HR]: () => <Hr />,
    [BLOCKS.TABLE]: (node, children) => <Table>{children}</Table>,
    [BLOCKS.TABLE_ROW]: (node, children) => <TableRow>{children}</TableRow>,
    [BLOCKS.TABLE_CELL]: (node, children) => <TableCell>{children}</TableCell>,
    [BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
      <TableHeaderCell>{children}</TableHeaderCell>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <Anchor href={node.data.uri} trackingContentEntry={trackingContentEntry}>
        {children}
      </Anchor>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      const contentType = getContentType(node);
      const fields = getFields(node);
      const { align } = fields;

      switch (contentType) {
        case 'genericImage':
          return (
            <Align align={align}>
              <EmbeddedImage {...fields}>{children}</EmbeddedImage>
            </Align>
          );
        case 'embeddedVideo':
          return <EmbeddedVideo {...fields}>{children}</EmbeddedVideo>;
        case 'embeddedFrame':
          return <EmbeddedFrame {...fields}>{children}</EmbeddedFrame>;
        case 'embeddedHighlight':
          return <EmbeddedHighlight {...fields}>{children}</EmbeddedHighlight>;
        case 'embeddedButton': {
          return (
            <EmbeddedButton
              trackingContentEntry={trackingContentEntry}
              {...fields}
            >
              {children}
            </EmbeddedButton>
          );
        }
        case 'embeddedRichtext':
          return <EmbeddedRichtext {...fields}>{children}</EmbeddedRichtext>;
        case 'embeddedCtaBanner':
          return <EmbeddedCtaBanner {...fields} />;
        default:
          return null;
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const contentType = getContentType(node);
      const fields = getFields(node);

      if (
        node?.data?.target?.contentType === 'nt_mergetag' &&
        node?.data?.target?.nt_mergetag_id &&
        NINETAILED_IS_ENABLED
      ) {
        return <MergeTag id={node.data.target.nt_mergetag_id} />;
      }

      switch (contentType) {
        case 'embeddedVariable':
          return <EmbeddedVariable {...fields} />;
        case 'embeddedLink':
          return <EmbeddedLink {...fields} />;
        default:
          return null;
      }
    },
  };
}

export const renderMark = {
  [MARKS.BOLD]: (text) => (
    <StyledBodyBold
      as="strong"
      size="one"
      variant="highlight"
      css={spacing({ bottom: 'mega' })}
    >
      {text}
    </StyledBodyBold>
  ),
  [MARKS.ITALIC]: (text) => (
    <StyledBodyItalic as="em" size="one" css={spacing({ bottom: 'mega' })}>
      {text}
    </StyledBodyItalic>
  ),
};
