import React from 'react';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import { IMAGE_BACKGROUND, IMAGE_DISPLAY } from '../../constants';
import { ImageProps } from '../../interfaces';
import * as EmbeddedImageService from '../../../EmbeddedImage/EmbeddedImageService';

import Image from '~/shared/components/Image';

const imageWrapperStyles = ({ theme }: { theme: Theme }) => css`
  width: 288px;
  align-self: stretch;
  margin-right: ${theme.spacings.tera};
  display: flex;
  flex-direction: column;

  ${theme.mq.untilGiga} {
    width: 260px;
    margin-right: ${theme.spacings.giga};
  }

  ${theme.mq.untilKilo} {
    width: 100%;
    margin-right: 0;
    margin-bottom: calc(${theme.spacings.mega} + ${theme.spacings.bit});
  }
`;

const ImageWrapper = styled.div(imageWrapperStyles);

const imageContainerStyles = ({
  theme,
  imageBackground,
}: {
  theme?: Theme;
  imageBackground: string;
}) => css`
  border-radius: ${theme.borderRadius.kilo};
  background-color: ${imageBackground === IMAGE_BACKGROUND.GREY
    ? 'var(--cui-bg-highlight)'
    : 'var(--cui-bg-normal)'};
  overflow: hidden;
  height: 100%;
`;

const ImageContainer = styled.div(imageContainerStyles);

const imageStyles = ({ theme }: { theme?: Theme }) => css`
  border-radius: ${theme.borderRadius.kilo};
  display: flex;
  height: 100%;
  align-items: center;
  > div {
    border-radius: ${theme.borderRadius.kilo};
  }
`;
const imageFillStyles = ({ fillImage }: { fillImage: boolean }) =>
  fillImage &&
  css`
    > div {
      height: 100%;
    }

    img {
      object-fit: cover;
      height: 100%;
    }
  `;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const StyledImage = styled(Image)(imageStyles, imageFillStyles);

const imageCaptionStyles = ({ theme }: { theme?: Theme }) => css`
  margin: ${theme.spacings.byte};
  color: var(--cui-fg-subtle);
  font-size: ${theme.typography.body.two};
  text-align: center;
`;

const ImageCaption = styled('p')(imageCaptionStyles);

function EmbeddedCTAImage({
  theme,
  image,
  imageDisplay,
  imageBackground,
  imageCaption,
}: ImageProps) {
  const fillImage = imageDisplay === IMAGE_DISPLAY.FILL;
  const src = image.file?.url;
  const alt = image.file?.description;
  const aspectRatio = EmbeddedImageService.getAspectRatio(image.file);
  const width = EmbeddedImageService.getWidth(image.file);
  const height = EmbeddedImageService.getHeight(width, aspectRatio);
  const srcSet = EmbeddedImageService.getSrcSet(src, aspectRatio);
  const sizes = EmbeddedImageService.getSizes(theme);
  const imageProps = {
    width,
    height,
    srcSet,
    src,
    sizes,
    aspectRatio,
    alt,
    fillImage,
  };

  return (
    <ImageWrapper>
      <ImageContainer imageBackground={imageBackground}>
        <StyledImage {...imageProps} />
      </ImageContainer>
      {imageCaption && <ImageCaption>{imageCaption}</ImageCaption>}
    </ImageWrapper>
  );
}

export default EmbeddedCTAImage;
